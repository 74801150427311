<template>
	<div class="kd-account-recharge">
        <div @click="data.show = true">
            <slot></slot>
        </div>
        <a-modal v-model:visible="data.show" :title="type==1 ?'余额充值' :'积分充值'" @ok="sureRecharge" width="800px">
            <a-form ref="form" v-model="data.form" :label-col="{span:4}" :wrapper-col="{span:18}" v-if="type== 1">
				<a-form-item label="操作">
					<a-radio-group v-model:value="data.form.changeType">
					    <a-radio :value="1">增加</a-radio>
					    <a-radio :value="2">减少</a-radio>
					    <a-radio :value="5">冻结 <span style="color: rgb(255, 46, 46);">(已冻结金额：{{frozen}})</span></a-radio>
					    <a-radio :value="6">解冻 <span style="color: rgb(255, 46, 46);">(已冻结金额：{{frozen}})</span></a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="金额">
					<a-input type="number" v-model:value="data.form.bank"></a-input>
				</a-form-item>
				<a-form-item label="备注">
					<a-input type="textarea" v-model:value="data.form.remark"></a-input>
				</a-form-item>
			</a-form>
			
			<!-- 积分操作 -->
			<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:18}" v-if="type==2">
				<a-form-item label="积分数">
					<a-input v-model:value="data.form.bank"></a-input>
				</a-form-item>
				<a-form-item label="备注">
					<a-input type="textarea" v-model:value="data.form.remark"></a-input>
				</a-form-item>
			</a-form>
        </a-modal>
	</div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import tool from '@/util/tool.js';
import { $post } from '@/api/http'
export default{
    name:'com-account-recharge',
    props:{
        type:{
            type:Number,
            value:1, //1余额充值 2积分充值
        },
        userId:{	//用户uid
            type:[Number,String],
            value:0, 
        },
        frozen:{	//已冻结金额
            type:[Number,String],
            value:0,
        }
    },
    setup(props,context){
        const t = getCurrentInstance().proxy
        const data = reactive({
            form:{
                changeType:1,
                bank:'',
                remark:''
            },
            show:false
        })

        function sureRecharge(){
            let { form } = data
            if( form.bank <= 0 ){
                tool.message("请输入要操作的金额！","warning")
                return
            }
            if( form.remark =="" ){
                tool.message("请输入备注信息","warning")
                return
            }

            if( props.type == 1 ){
                let param = {
                    type: 1,
                    pk:props.userId,
                    ...form
                }
                let title = '增加';
                if( param.changeType == 2 ) title = '减少'
                if( param.changeType == 5 ) title = '冻结'
                if( param.changeType == 6 ) title = '解冻'
                tool.confirm(`确认${title}该账户${ form.bank}元钱吗？`).then(()=>{
                    $post("changeGoldMoney",param).then(()=>{
                        tool.message("操作成功")
                        data.show = false
                        data.form.remark = ''
                        data.form.bank =0
                        context.emit("success")
                    })
                }).catch(()=>{})
            }

            if( props.type== 2 ){
                let param ={
                    user_id:props.userId,
                    score:form.bank,
                    score_remark:form.remark,
                }
                //积分/余额充值
                $post("IntegralRecharge",param).then(()=>{
                    tool.message("充值成功")
                    data.show = false
                    data.form.remark = ''
                    data.form.bank =0
                    context.emit("success")
                })
            }
        }
        return {
            data,
            sureRecharge
        }
    },
}
</script>

<style>
</style>
